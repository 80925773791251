import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'articlefilter',
    pure: false
})
export class MyArticleFilterPipe implements PipeTransform {
    transform(items: any[], filter: Object, slice:number): any {
        if (!items || !filter) {
            return items;
        }
        if (slice > 0) {
            return items.filter(item => item.isActive == true).slice(0, slice);
        }

        return items.filter(item => item.isActive == true).slice(2);
    }
}