import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarWhiteComponent } from './navbar-white/navbar-white.component';
import { FooterNewComponent } from './footer-new/footer-new/footer-new.component';
import { NavbarSharedComponent } from './navbar-shared/navbar-shared.component';
import {RouterModule} from '@angular/router';
@NgModule({
  imports: [
    CommonModule,RouterModule
  ],
  declarations: [NavbarWhiteComponent, FooterNewComponent, NavbarSharedComponent ],
  exports: [
    NavbarWhiteComponent,
    FooterNewComponent,
    NavbarSharedComponent 
 ]
})
export class SharedModulesModule { }
