import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { AppComponent } from './app.component';
import { EducateModule } from './educate/educate.module';
import { AppConfigService } from '../Service/app-config.service';
import {SharedModulesModule} from './shared-modules/shared-modules.module';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ThankYouPageComponent } from './contact-us/thank-you-page/thank-you-page.component';
import { SucessPageComponent } from './sucess-page/sucess-page.component';
const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

const routes: Routes = [
 // { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'privacy-policy', loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  { path: 'terms-conditions', loadChildren: () => import('./terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule) }, 
  { path: 'cookie-policy', loadChildren: () => import('./cookie-policy/cookie-policy.module').then(m => m.CookiePolicyModule) },
  { path: 'how-to-complain', loadChildren: () => import('./how-to-complain/how-to-complain.module').then(m => m.howtocomplainModule) },
  { path: 'fca-services', loadChildren: () => import('./faq/faq.module').then(m => m.faqModule) },
  { path: 'credit-search', loadChildren: () => import('./credit-search/credit-search.module').then(m => m.CreditSearchModule) },
  { path: 'open-banking', loadChildren: () => import('./for-empolyers/for-empolyers.module').then(m => m.forempolyersModule) },
  { path: 'probate', loadChildren: () => import('./probate/probate.module').then(m => m.ProbateModule) },
  { path: 'webawsservices', loadChildren: () => import('./web-aws-services/web-aws-services.module').then(m => m.WebAwsServicesModule) },
  { path: 'contact-us', loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule) },
  { path: 'resources', loadChildren: () => import('./educate/educate.module').then(m => m.EducateModule) },
  { path: 'thank-you', component:ThankYouPageComponent },
  { path: 'success-page', component:SucessPageComponent },
  { path: 'fca-ar-status-and-lending', loadChildren: () => import('./google-ads-landing-page/google-ads-landing-page.module').then(m => m.GoogleAdsLandingPageModule) },
];

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'tour-of-heroes' }),
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    EducateModule,
    SharedModulesModule,
    RouterModule.forRoot(routes)
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService]
    },

  ],
  bootstrap: [ AppComponent ],
  exports: [
    SharedModulesModule
  ],
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
