<app-navbar-white></app-navbar-white>
<div class="spinner-container-box" *ngIf="Loader">
    <div class="spinner-container">
      <div class="spinner-border spinner-border-sm" role="status"></div>
      <div class="text-loader">
        Please wait while we are gathering data...
      </div>
   </div>
</div>
<div class="section-conatiner" *ngIf="!Loader">

<div class="resource-section">
<img loading="eager" class="img-fluid lazyload" id="section-bg" src="{{Article?.data?.showPageImageLink?.iv}}" alt="image not found">
</div>
<div class="width-r container-fluid">
<div class="box-heading">{{Article.data?.articleTitle?.iv}}</div> 
<div class="d-flex b-nd">
<div class="tittle-article">{{Article.data?.category?.categoryText[0]}}</div>
<div class="d-flex  width-box"><img loading="eager" class="img-fluid lazyload img-box" src="{{imgSrc}}Vector-clock-n.svg" alt="image not found"><div class="mins-count">{{Article.data?.readTime?.iv}} read</div>
</div>
</div>
<div class="d-flex justify-content-center">
<div class="col-md-12 col-lg-12 col-sm-12"> 
<div class="d-flex justify-content-center"> 
<div class="col-lg-9 col-md-9 col-sm-12">
<div class="txt-simple">{{Article.data?.subheading?.iv}}</div>
<div class="txt-para" [innerHTML]="Article?.data?.articleText?.iv" (click)="AnchorClick($event)">
</div></div></div>



<div class="heading-latest">Related Articles</div>
<div class="row">
<div class="col-lg-4 col-md-4 col-sm-12 new-v"  *ngFor="let refArticle of relatedArticles">
<div class="box-news" data-toggle="modal" data-target="#modalarticle" (click)="DisplayRelatedArticle(refArticle?.id)">
<img src="{{refArticle?.showPageImageLink?.iv}}" alt="image not found" class="img-fluid news-img">
<div class="box-area">
<div class="time-min"><em class="far fa-clock"></em> {{refArticle?.readTime?.iv}} read </div>
<div class="heading-news">{{refArticle?.articleTitle?.iv}}</div>
<button type="button" class="btn read-btn" data-toggle="modal" data-target="#modalarticle" (click)="DisplayRelatedArticle(refArticle?.id)">Read</button>
</div></div></div>
</div> 
</div>

<!---<div class="col-md-3 col-lg-3 col-sm-12 padding-box-new">
<div class="author-txt">Author</div>
<div class="author-heading">{{Article?.data?.author?.iv}}</div>
<div class="marginset">
<div class="share-txt">Share</div>
<div class="d-flex d-m">
<img loading="lazy" (click)="redirect('facebook')" class="img-fluid lazyload img-social" src="{{imgSrc}}fb.svg" alt="image not found">
<img loading="lazy" (click)="redirect('twitter')" class="img-fluid lazyload img-social" src="{{imgSrc}}pinterest.svg" alt="image not found">
<img loading="lazy" (click)="redirect('pinterest')" class="img-fluid lazyload img-social" src="{{imgSrc}}skype-d.svg" alt="image not found">
</div></div></div>-->



</div>
</div>


</div>
<app-footer-new></app-footer-new>