<div class="fixed-top" id="navbar" [ngClass]="isShow?'sticky':''">
    <nav class="navbar navbar-expand-lg sticky-top ">
        <a [routerLink]="['/']" class="navbar-brand">
            <img id="logo_white" [ngClass]="isShow?'disd-none':''"  src="{{imgSrc}}Logo.png" class="img-fluid" alt="image not found">
            <img id="logo_Dark" [ngClass]="isShow?'disd-dnone':''"  src="{{imgSrc}}Logo.png" alt="image not found" class="img-fluid disd-none">
        </a>
        <button id="navButton" data-toggle="modal" data-target="#myModal" class="navbar-toggler navbar-toggler-right collapsed" type="button"  >
     <span>
     <img class="img-fluid" id="showne" [ngClass]="isShow?'disd-none':''" src="{{imgSrc}}menu.svg" alt="image not found">
     <img id="showele" class="img-fluid disd-none" [ngClass]="isShow?'disd-dnone':''" src="{{imgSrc}}menu.svg" alt="image not found"></span>
        </button>
        
        <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul class="navbar-nav dispaly-web" >
               

            
                <li class="nav-item padding-nav-item">
                    <a [routerLink]="[ '/fca-services']"  id="navabout" [ngClass]="isShow?'color-nav':''"  routerLinkActive="active" class="nav-link">FCA Services</a>
                </li>
                <li class="nav-item padding-nav-item">
                    <a [routerLink]="[ '/credit-search']"  id="navabout" [ngClass]="isShow?'color-nav':''"  routerLinkActive="active" class="nav-link">Credit Search</a>
                </li>
                <li class="nav-item padding-nav-item">
                    <a [routerLink]="[ '/open-banking']"  id="navabout" [ngClass]="isShow?'color-nav':''"  routerLinkActive="active" class="nav-link">Open Banking</a>
                </li>
                <!---<li class='dropdown'>
                    <a data-toggle="dropdown" href="../home"  id="navlist" [ngClass]="isShow?'color-nav':''"  routerLinkActive="active" class="nav-link dropdown-toggle active">Services <i class="fa fa-chevron-down" aria-hidden="true"></i></a>
                    <ul class="dropdown-menu" role="menu">
                        <li> <a href="../credit-search" routerLinkActive="active" >Credit Search</a></li>
          <li><a href="../open-banking" >Open Banking</a></li>
      <li> <a href="../marketplace" > Marketplace Services</a></li>
          <li><a href="../probate" >Probate Services</a></li>
<li><a href="../webawsservices" >Web Applications/ Hosting</a></li>
                    </ul>
                  </li>-->
            
                <li class="nav-item">
                    <div> <a class="signuplink" href="/contact-us" routerLinkActive="active" ><button class=" btn btn-sign-up" id="signbtn" [ngClass]="isShow?'btn-ho':''">Contact Us
                              </button></a></div>
                </li>
            </ul>
        </div>
    </nav>
</div>
<!-- Modal -->
<div class="modal right fade" id="myModal" tabindex="-1"  role="dialog" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
           <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"> 
            <img  class="img-fluid" src="{{imgSrc}}close-nav.svg" alt="image not found">
           </span></button>
            </div>

            <div class="modal-body">
                <a class="credit-respo" data-dismiss="modal" [routerLink]="[ '/fca-services']"  routerLinkActive="active">FCA Services</a>
      <a class="credit-respo" data-dismiss="modal"  [routerLink]="[ '/credit-search']"  routerLinkActive="active">Credit Search</a>
      <a class="credit-respo" data-dismiss="modal" [routerLink]="[ '/open-banking']"  routerLinkActive="active">Open Banking</a>
      <div class="margin-btn"> <a class="signuplink" href="/contact-us" ><button class=" btn btn-sign-up" id="signbtn" >Contact Us
      </button></a></div>
            </div>

        </div><!-- modal-content -->
    </div><!-- modal-dialog -->
</div><!-- modal -->

<div class="fixed-top" id="navbar" [ngClass]="isShow?'sticky':''">
    <nav class="navbar navbar-expand-lg sticky-top ">
        <a [routerLink]="['/']" class="navbar-brand">
            <img id="logo_white" [ngClass]="isShow?'disd-none':''"  src="{{imgSrc}}Logo.png" class="img-fluid" alt="image not found">
            <img id="logo_Dark" [ngClass]="isShow?'disd-dnone':''"  src="{{imgSrc}}Logo.png" alt="image not found" class="img-fluid disd-none">
        </a>
        <button id="navButton" data-toggle="modal" data-target="#myModal" class="navbar-toggler navbar-toggler-right collapsed" type="button"  >
     <span>
     <img class="img-fluid" id="showne" [ngClass]="isShow?'disd-none':''" src="{{imgSrc}}menu.svg" alt="image not found">
     <img id="showele" class="img-fluid disd-none" [ngClass]="isShow?'disd-dnone':''" src="{{imgSrc}}menu.svg" alt="image not found"></span>
        </button>
        
        <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul class="navbar-nav dispaly-web" >
               

            
                <li class="nav-item padding-nav-item">
                    <a [routerLink]="[ '/fca-services']"  id="navabout" [ngClass]="isShow?'color-nav':''"  routerLinkActive="active" class="nav-link">FCA Services</a>
                </li>
                <li class="nav-item padding-nav-item">
                    <a [routerLink]="[ '/credit-search']"  id="navabout" [ngClass]="isShow?'color-nav':''"  routerLinkActive="active" class="nav-link">Credit Search</a>
                </li>
                <li class="nav-item padding-nav-item">
                    <a [routerLink]="[ '/open-banking']"  id="navabout" [ngClass]="isShow?'color-nav':''"  routerLinkActive="active" class="nav-link">Open Banking</a>
                </li>
                <!---<li class='dropdown'>
                    <a data-toggle="dropdown" href="../home"  id="navlist" [ngClass]="isShow?'color-nav':''"  routerLinkActive="active" class="nav-link dropdown-toggle active">Services <i class="fa fa-chevron-down" aria-hidden="true"></i></a>
                    <ul class="dropdown-menu" role="menu">
                        <li> <a href="../credit-search" routerLinkActive="active" >Credit Search</a></li>
          <li><a href="../open-banking" >Open Banking</a></li>
      <li> <a href="../marketplace" > Marketplace Services</a></li>
          <li><a href="../probate" >Probate Services</a></li>
<li><a href="../webawsservices" >Web Applications/ Hosting</a></li>
                    </ul>
                  </li>-->
            
                <li class="nav-item">
                    <div> <a class="signuplink" href="/contact-us" routerLinkActive="active" ><button class=" btn btn-sign-up" id="signbtn" [ngClass]="isShow?'btn-ho':''">Contact Us
                              </button></a></div>
                </li>
            </ul>
        </div>
    </nav>
</div>
<!-- Modal -->
<div class="modal right fade" id="myModal" tabindex="-1"  role="dialog" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
           <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"> 
            <img  class="img-fluid" src="{{imgSrc}}close-nav.svg" alt="image not found">
           </span></button>
            </div>

            <div class="modal-body">
                <a class="credit-respo" data-dismiss="modal" [routerLink]="[ '/fca-services']"  routerLinkActive="active">FCA Services</a>
      <a class="credit-respo" data-dismiss="modal"  [routerLink]="[ '/credit-search']"  routerLinkActive="active">Credit Search</a>
      <a class="credit-respo" data-dismiss="modal" [routerLink]="[ '/open-banking']"  routerLinkActive="active">Open Banking</a>
      <div class="margin-btn"> <a class="signuplink" href="/contact-us" ><button class=" btn btn-sign-up" id="signbtn" >Contact Us
      </button></a></div>
            </div>

        </div><!-- modal-content -->
    </div><!-- modal-dialog -->
</div><!-- modal -->

