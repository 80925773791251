import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-sucess-page',
  templateUrl: './sucess-page.component.html',
  styleUrls: ['./sucess-page.component.css']
})
export class SucessPageComponent implements OnInit {

  constructor( private titleService:Title){   
    this.titleService.setTitle("Success - Creative Finance"); }
  imgSrc = environment.imgBasURL;
  ngOnInit(): void {
  }

}
