import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-navbar-white',
  templateUrl: './navbar-white.component.html',
  styleUrls: ['./navbar-white.component.css']
})
export class NavbarWhiteComponent implements OnInit {
  API = environment.apiUrl;
  icon: boolean = false;
  imgSrc = environment.imgBasURL;
  GoogleURL = environment.GoogleStore;
  AppleUrl = environment.PlayStore;
  SignUP = environment.signUrl;
  btnuse = true;
  isShow: boolean = false;
  constructor() { }
  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.isShow = false;
    var newelement = document.getElementById("navButton");
    if (!newelement.classList.contains('collapsed')) {
      newelement.click();
    }

  }

  ngOnInit() {
    window.onscroll = function () { myFunction() };
    var navbar = document.getElementById("navbar");
    var navlist = document.getElementById("navlist");
    var navabout = document.getElementById("navabout");
    var logo_white = document.getElementById("logo_white");
    var logo_Dark = document.getElementById("logo_Dark");
  
    var showele = document.getElementById("showele");
    var showne = document.getElementById("showne");
    function myFunction() {
      if (window.scrollY >= 10) {
        showele.classList.add("disd-dnone");
        showne.classList.add("disd-none");
        navbar.classList.add("sticky");
        logo_white.classList.add("disd-none");
        logo_Dark.classList.add("disd-dnone");
        navlist.classList.add("color-nav");
        navabout.classList.add("color-nav");

      } else {
        showele.classList.remove("disd-dnone");
        showne.classList.remove("disd-none");
        navbar.classList.remove("sticky");
        logo_white.classList.remove("disd-none");
        logo_Dark.classList.remove("disd-dnone");
        navlist.classList.remove("color-nav");
        navabout.classList.remove("color-nav");
      }
    }
  }

  changeColor() {
    if (window.scrollY >= 10) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }
  changeColor2() {
    var navbar = document.getElementById("navbar");
    navbar.classList.add("sticky")
    this.isShow = true;
  }
}
