import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-footer-new',
  templateUrl: './footer-new.component.html',
  styleUrls: ['./footer-new.component.css']
})
export class FooterNewComponent implements OnInit {
  imgSrc = environment.imgBasURL;
  constructor() { }
year: number = new Date().getFullYear();
  ngOnInit() {
  }

}
