import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class ArticleService {
    constructor(private _http: HttpClient) { }
    cmsUrl = environment.baseUrl;
    headers = new HttpHeaders({'domainKey': environment.configUrl.DomainKey });

    Categories(){
        return this._http.get(this.cmsUrl+'/public-cms/Categories',{ headers: this.headers });
    }

    AllArticles(){
        return this._http.get(this.cmsUrl+'/public-cms/AllArticles',{ headers: this.headers });
    }

    ArticleBySlug(slug:any){
        const params = new HttpParams().set('slug', slug).set('domainKey',environment.configUrl.DomainKey);
        return  this._http.get(this.cmsUrl + '/public-cms/GetArticleBySlug',{params});
    }
}
