import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.css']
})
export class ThankYouPageComponent implements OnInit {
  imgSrc = environment.imgBasURL;
  constructor( private titleService:Title){   
    this.titleService.setTitle("Thank You - Creative Finance"); }

  ngOnInit() {
  }

}
