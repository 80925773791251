<div class="container"> 
    <img id="logo_Dark" src="{{imgSrc}}Logo.png" alt="image not found" class="img-fluid">



<div class="text-center">
<img src="{{imgSrc}}success-image.svg" alt="image not found" class="img-fluid img-c">
<div class="thank-you-text">Thank you for getting in touch.</div>
<div class="text-para-thank-you">Your message has been sent! We will get back to you within the next 48 hours.</div>
<a href="./"><button type="button" class="btn btn-submit">Visit Our Website</button></a></div>


</div>
