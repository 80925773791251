export class Category {
    iv: string;
}

export class CategoryText {
    iv: string;
}

interface Img {
    iv: any[];
}

interface ClassName {
    iv: string;
}

interface OrderNo {
    iv: number;
}

export class Data {
    category: Category;
    categoryText: CategoryText;
    img: Img;
    className: ClassName;
    orderNo: OrderNo;
}

export class ArticalCategory {
    id: string;
    createdBy?: string;
    lastModifiedBy?: string;
    data: Data;
    isPending: boolean;
    created: Date;
    lastModified: Date;
    status?: any;
    statusColor?: any;
    version: number;
    _links?: string;
    isActive: boolean;
    articleCount:number
}

//Start Article Model

interface ArticleNestedCategory {
   iv: string[];
    categoryText: string[];
}

interface ArticleTitle {
    iv: string;
}

interface PublishedDate {
    iv: Date;
}

interface ReadTime {
    iv: string;
}

interface HoldingPageImageLink {
    iv: string[];
}

interface ShowPageImageLink {
    iv: string[];
}

interface IvData {
}

interface ContentDataUri {
    uri: string;
}

interface Mark {
    type: string;
}

interface Content {

    data: ContentDataUri;
    marks: Mark[];
    value: string;
    nodeType: string;
    content: Content[];
}

interface ArticleTextContent {

    data: Content;
    content: Content[];
    nodeType: string;
}

interface Iv {
    data: IvData;
    content: ArticleTextContent[];
    nodeType: string;
}

interface ArticleText {
    iv: Iv;
}

interface Author {
    iv: string;
}

interface ShortArticalText {
    iv: string;
}

interface Subheading {
    iv: string;
}

interface SubTitle {
    iv?: any;
}

interface CallToAction {
    iv: string;
}

interface CallToActionURL {
    iv: string;
}

interface RefRelatedArticlesIv {
    id: string;
    slug: string;
    category: ArticleNestedCategory;
    articleTitle: ArticleTitle;
    publishedDate: PublishedDate;
    readTime: ReadTime;
    holdingPageImageLink: HoldingPageImageLink;
    showPageImageLink: ShowPageImageLink;
    articleText: ArticleText;
    author: Author;
    source?: any;
    refRelatedArticles?: any;
    shortArticalText: ShortArticalText;
    status?: any;
    subheading: Subheading;
    subTitle: SubTitle;
    callToAction: CallToAction;
    callToActionURL: CallToActionURL;
}
interface RefRelatedArticles {
    iv: RefRelatedArticlesIv[];
}

interface ArticleNestedData {

    id: string;
    slug: string;
    category: ArticleNestedCategory;
    articleTitle: ArticleTitle;
    publishedDate: PublishedDate;
    readTime: ReadTime;
    holdingPageImageLink: HoldingPageImageLink;
    showPageImageLink: ShowPageImageLink;
    articleText: ArticleText;
    author: Author;
    source?: any;
    refRelatedArticles: RefRelatedArticles;
    shortArticalText: ShortArticalText;
    status?: any;
    subheading: Subheading;
    subTitle: SubTitle;
    callToAction: CallToAction;
    callToActionURL: CallToAction;
}

export class ArticleModel {
    id: string;
    createdBy?: any;
    lastModifiedBy?: any;
    data: ArticleNestedData;
    isPending: boolean;
    created: Date;
    lastModified: Date;
    status?: any;
    statusColor?: any;
    version: number;
    _links?: any;
    isActive:boolean
}