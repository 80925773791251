<footer id="footer">
  <div id="footer-top" class="container">
    <div class="d-flex flex-wrap footer-center">
      <div id="footer-logo-container">
        <h1><a href="../" >
            <img id="footer-logo" src="{{imgSrc}}Logo.png" class="img-fluid">
          </a></h1>
      </div>
      <div id="Company-container">
        <h6 class="footer-title">Services</h6>
       <div class="list-group">
        <a class="footer-link" [routerLink]="[ '/fca-services']" routerLinkActive="active">FCA Services</a>
        <a class="footer-link" [routerLink]="[ '/credit-search']" routerLinkActive="active">Credit Search</a>
        <a class="footer-link" [routerLink]="[ '/open-banking']" routerLinkActive="active">Open Banking</a>
       <!---<a class="footer-link" href="../marketplace" routerLinkActive="active">FCA Services</a>
        <a class="footer-link" href="../probate" routerLinkActive="active">Probate Services</a>-->
        <!----<a class="footer-link" href="../webawsservices" routerLinkActive="active">Web Applications/ Hosting</a>--->

        </div>
      </div>
      <div id="contact-container" class="d-footer">
        <div id="btn-contact-us-container">
          <a href="../contact-us"><button id="btn-contact-us" >Contact Us</button></a>
        </div>
        <div class="list-group">
          <div class="addres-txt">20-22 Wenlock Road, London, N1 7GU</div>
        </div>
      </div>
      <div id="legal-compliance-container">
        <h6 class="footer-title">Legal & Compliance </h6>
        <div class="list-group ">
          <a class="footer-link" routerLinkActive="active" [routerLink]="['/terms-conditions']"  [routerLinkActiveOptions]="{exact:true}" > Terms & Conditions </a>
          <a class="footer-link" routerLinkActive="active" [routerLink]="['/privacy-policy']"  [routerLinkActiveOptions]="{exact:true}" > Privacy Policy </a>
          <a class="footer-link" routerLinkActive="active" [routerLink]="['/how-to-complain']"  [routerLinkActiveOptions]="{exact:true}" > How to Complain</a>
          <a class="footer-link" routerLinkActive="active" [routerLink]="['/cookie-policy']"  [routerLinkActiveOptions]="{exact:true}" > Cookie Policy</a>
          
        </div>
      </div>
      <div id="contact-container"  class="d-web-footer">
        <div id="btn-contact-us-container">
          <a href="../contact-us"><button id="btn-contact-us" >Contact Us</button></a>
        </div>
        <div class="list-group">
          <div class="addres-txt">20-22 Wenlock Road, London, N1 7GU</div>
        </div>
      </div>
    </div>
  </div>
  <div id="footer-bottom-txt-container">
    <div id="footer-bottom-txt">
      Copyright © {{year}} Creative Finance. All rights reserved. Creative Finance is a trading style of Creative Finance Corp LTD, authorised and regulated by the financial conduct authority under reference FRN 702435. </div></div>
    </footer>


<!------Modal start-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title top-heading" id="exampleModalLongTitle">Cookie Policy</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">   <img src="../{{imgSrc}}closeiconcross.svg" alt="image not found"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="normal-txt">
          Lifezy uses cookies for a variety reasons, including ensuring that our website functions in accordance with the requirements of our business, our partners and users; logging your preferences and collecting information on the users of our services, including our website.
      </div>
      <div class="normal-txt">
        When you use our services you will agree that this Cookies Policy, together with our Privacy Policy and Terms and Conditions, are relevant and applicable.  References to our website below include references to our apps.
       </div>
       <div class="normal-txt">
        Protecting you, and any information that we collect about you online, is paramount. Our Cookies Policy informs you about why we use cookies, and how we use Cookies to improve the services we provide.
      </div>

      <div class="sub-heading">
        What are cookies?
      </div>
      <div class="normal-txt">
        Cookies are files containing small amounts of information downloaded to your device. We and our third party service providers use both session cookies and persistent cookies.
       </div>
<div class="session-cookies">
  <ul class="ul-margin">
  <li><span class="bold-font">	Session cookies</span> last for the length of your visit and are deleted as soon as you close your browser. They allow us to follow a user’s journey and identify the movement between pages; support website security and functionality.
  </li>
  <li>
    <span class="bold-font">Persistent cookies</span> stay on your device after your browser has been closed, and enable a website to remember your references and actions, thus improving the service for you. Every time you visit the website where the cookie was generated, they are activated. Persistent cookies can be used by websites to target advertising based on the browsing history of the device. They are stored by the browser and will be valid until their set expiry date.  They can be deleted by the user before that expiry date.
 </li> </ul> </div> 
    <div class="session-cookies space-between">
      Cookies may be either "persistent" cookies or "session" cookies, depending on how long they are used.
    </div>
    <div class="session-cookies space-between">
      The majority of cookies we use are session cookies. They help us to ensure the security of your session, and enable you to remain signed in to your Lifezy account as you navigate between pages.
    </div><div class="session-cookies space-between">
      We use cookies for numerous reasons, including improving our website and making it easy for you and all other users to use, and also to remember your preferences.
    </div>
    <div class="session-cookies space-between">
      Your web browser - Google Chrome, Internet Explorer, Safari or Mozilla Firefox, or any similar browser; send cookies back to our website or application every time you visit our website.
    </div>
    <div class="session-cookies">
      We, and our third party service providers, use the information the cookies provide about you for the following purposes:
    </div>
    <div class="session-cookies space-between-ul">
<ul class="ul-margin">
  <li>
   to recognise your device/devices whenever you visit our website and log any preferences you have set on our website, which allows us to personalise our content for you, including using your name
  </li>
  <li>to remember if you’ve previously been informed of and agreed to our Cookies Policy, Privacy Policy and other Terms and Conditions</li>
  <li>to analyse use of our website including for but not limited to, sales and marketing research, and performance and statistical analysis.</li>
  <li>to test out new content and analyse whether it is effective</li>
  <li>to ensure our website is easy to use and up to date</li>
  <li>to temporarily store certain information you give us, for example, as part of customer surveys</li>
  <li>to target information that is most relevant to you, including our notifications, website content, marketing campaigns and direct marketing emails, as well as evaluating our Internet banner advertisements on our website and on third party websites</li>
<li> to monitor traffic to our website and analyse how you navigate within it, such as tracking page usage and paths
  used; and tracking use of our Internet banner advertisements and other links from our marketing partners’
  websites to our website</li>
<li>to allow you to share pages with social networks such as Facebook, Twitter, Instagram and LinkedIn and to
  support social media components, like Facebook or Twitter (where our website uses a plugin from these third
  party platforms)</li>
</ul></div>
<div class="session-cookies space-between">If you prefer us not to use these cookies, then you can disable cookies via a setting in your browser (see information on ‘Turning Cookies Off’ below.)
</div>
<div class="session-cookies space-between">We may use the data we get from Cookies on your web history and usage with other relevant information we have collected from you, or about you. We securely store this information in order for us to have a greater and more focused understanding of how you and other users are using our website, as well as user’s interests and preferences, for the reasons listed above.
</div>
<div class="session-cookies space-cookies"><div class="bold-font">More about the cookies we use</div>The cookies we use are both cookies that we set, which are referred to as first party cookies, and cookies managed for us by third parties.
</div>
<div class="session-cookies space-between">A cookie is either a first or third party cookie depending on the website the cookie comes from.
</div><div class="session-cookies space-between">
First party cookies are created by the host website or domain visited, in this case Lifezy.  All other cookies are third party cookies. These cookies log each visit to our website, the pages you visited and the links you followed. They also record how you used the site, for example where you hovered the cursor; how long it took you to click a link etc. We use this information to improve our website and your user experience, and to make the website and advertising shown to you as relevant to your preferences as possible. We may also share this information with third parties for this purpose. We use retargeting and online behavioural advertising to market Lifezy which employs targeting cookies to deliver you more targeted advertising.</div>
<div class="session-cookies space-between"><span class="bold-font">Third party cookies: </span> are created by website and domains other than the one you are visiting directly. The cookies collect information in an anonymous form, including the number of visitors to the site, where visitors have come to the website from and the pages they visited, retargeting and ad-serving.  Cookies which are managed for us by third parties, are managed in line with the purposes listed above and cannot be used for any other reason.
</div>
<div class="session-cookies space-between"><span class="bold-font">Web analytics services: </span>we use web analytics services in order understand how you and other users navigate and use our website.  We do this to ensure our services are easy to use and our products are relevant and up-to-date. The benefit is being able to test different page designs or features, to see which is preferred, identify bugs or errors, and see what services we offer are most popular. These web analytics services may be provided by other companies on our behalf. They do this using small invisible images known as “web beacons” or “tracking pixels” that may be included in the digital products and services on our website.
</div>
<div class="session-cookies space-between">By using our website, you agree that we can place both first party and third party cookies and web analytic services on your device.
</div>
<div class="session-cookies space-between"><div class="bold-font">Specific cookies we use</div>
Below is a list of all the cookies we use, what they are used for, and whether they are ‘first party’ or ‘third party’. Where a cookie is a third party cookie, please visit the providers’ website for further information.
</div>
<div class="cookies-explain">Turning Cookies Off</div>
<div class="session-cookies space-between">
You can disable cookies within your browser, however this will mean that parts of our website will no longer function as you would expect and we will not be able to deliver our full service to you.
Browsers allow you to reject cookies and choose to enable do not track features.  Please check the links we have given below to learn how to do that.  
</div><div class="session-cookies space-between">
If you delete cookies any preferences they recorded will be no longer be logged. This will include opting out from cookies, as this requires an opt-out cookie to be set.
</div><div class="session-cookies space-between">
If you are concerned about third party cookies, you can reject those cookies, rather than all cookies, which will enable us to still provide our services to you with the functionality that you would expect and that our full service requires.
</div><div class="session-cookies space-between">
If you would like to stop your browser from accepting cookies, please see information on the following browser pages:
</div>
<div class="session-cookies space-between-ul">
<ul class="ul-margin">
<li>Google: <span class="link-color">https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en</span></li>
<li>Internet Explorer: <span class="link-color"> http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies</span></li>
<li>Chrome: <span class="link-color"> https://support.google.com/chrome/bin/answer.py?hl=en&answer=95647&p=cpn_cookies</span></li>
<li>Safari: <span class="link-color">https://support.apple.com/en-gb/guide/safari/sfri11471/mac</span></li>
<li>Mozilla Firefox: <span class="link-color">http://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</span></li>
</ul>
<div class="session-cookies space-cookies"><div class="bold-font">More about the cookies we use</div>
We reserve the right to make changes to our Cookies Policy. This page will be updates with any changes we may make to our Cookies Policy.  Whenever necessary and appropriate, we will notify you of changes to this policy by email. Please check back here frequently to see any updates or changes. Your continued use of our website is taken as your agreement to any such changes.
</div>
<div class="cookies-explain">Contact Us</div>
<div class="session-cookies space-cookies">If you have any questions about cookies, the cookies we use or our Cookies Policy, please contact us  support@lifezy.co.uk </div>

<div class="cookies-explain">Further Reading</div>
<div class="session-cookies space-between">
If you would like to know more about cookies, you can find more information through the links below.
<div  class="link-color">https://www.aboutcookies.org.uk/</div>
<div  class="link-color">http://www.allaboutcookies.org/</div>
<div  class="link-color">http://www.bbc.co.uk/webwise/guides/about-cookies</div>
<div  class="link-color">https://ico.org.uk/media/for-organisations/documents/1545/cookies_guidance.pdf</div>
<div  class="link-color">http://www.youronlinechoices.com/uk/download-guide</div>
</div>
</div></div></div>
</div></div>
<!------Modal How Complain start-->
<div class="modal fade" id="HowComplain" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title top-heading" id="exampleModalLongTitle">How to Complain</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">   <img src="../{{imgSrc}}closeiconcross.svg" alt="image not found"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="sub-heading">
          Complaints handling procedure
        </div>
        <div class="normal-txt">
          Any expression of dissatisfaction about the provision, or failure to provide, a financial service and redress determination/complaints handling procedure which alleges that the complainant has suffered (or may suffer) financial loss, material distress or material inconvenience is treated as a complaint. This includes correspondence in writing and that made during the course of verbal communication. Where any customer expresses concern about the service, steps should be taken to resolve that matter and it should be treated as a complaint.
        </div>
      <div class="normal-txt">
        Complaints may be received directly from customers or from third party representatives. In the event of any complaint the matter must be recorded and logged, and referred to the nominated complaints handler in accordance with our complaint handling procedure.
       </div>
       <div class="normal-txt">
        Any complaint received from a third party representative such as Trading Standards or the Citizens Advice Bureau will be referred to the nominated complaints handler.
      </div>
      <div class="normal-txt">
        It is our aim to always exceed your expectations and we hope you never find cause for dissatisfaction. However, we would encourage you to make us aware of any complaint you may have and give us the opportunity to put it right.
      </div>
      <div class="normal-txt">
        This notice outlines our complaints procedure, the type of complaints it will manage and handle, timescales, the process you should expect us to employ and where to go should we not be able to resolve your complaint, or should you not find a satisfactory resolution to your complaint.
       </div>
      <div class="normal-txt">
        This complaints procedure is aimed at resolving complaints quickly and satisfactorily and further improving the quality of our service. </div>
      <div class="sub-heading">
        What our complaints procedure covers
      </div>
      <div class="normal-txt">
        You may wish to make a complaint with regard to the services which we have provided to you, which include:  </div>
<div class="session-cookies">
  <ul class="ul-margin">
  <li> Manual errors or mistakes </li>
  <li>
    Unexplained delays
   </li> 
  <li> Staff behaviour / conduct</li>
  <li>Breach of contract / terms of business</li>
  </ul> </div> 
    <div class="session-cookies space-between">
      Lifezy (or it’s group of companies) is not responsible for any issues or disputes relating to your credit file. This data is owned and operate by our partner Experian. Any queries or complaints that relate to your credit profile should be directed to 
      <a href="https://www.experian.co.uk/consumer/product-factsheets/complaint-handling-procedure.pdf" class="link-color">https://www.experian.co.uk/consumer/product-factsheets/complaint-handling-procedure.pdf</a></div>
      <div class="sub-heading">
        How to make a complaint 
      </div>
      <div class="session-cookies space-between">
      If you are unhappy with the service we have provided to you for any of the above, you should in the first instance discuss the situation with a member of the Operations Team (operations@lifezy.co.uk). Should a satisfactory resolution not be reached, your complaint will be escalated to the Complaints Management Team. A written acknowledgement will be sent to you within 5 working days of receiving the complaint
    </div><div class="session-cookies space-between">
      In the first instance, should you prefer to, you can write a letter of complaint and post or email it to us. The same timescales will apply. Please find contact information at the bottom of this notice.
    </div>
    <div class="session-cookies space-between">
      If you are making a complaint we recommend you have the following information to hand, as this will allow your complaint to be handled quicker: 
    </div>
    <div class="session-cookies space-between-ul">
<ul class="ul-margin">
  <li>
    Your name and reference number </li>
  <li> Contact details – including preferred time and method of contact</li>
  <li>Nature of your complaint – including dates / timescales involved</li>
  <li>Names of any staff dealing with / involved in your complaint (where known) or provide a description of the staff
    (if possible) where their name is not known</li>
  <li>Any relevant additional information</li>
</ul></div>
<div class="sub-heading">
  How we will action your complaint
</div>
<div class="session-cookies space-between">Our Operations Team are trained to handle your complaint sensitively and efficiently. We will listen to you and seek to understand your experience in detail. We will ask questions and will ensure we have the full picture before taking action or providing a response / resolution.

</div>
<div class="session-cookies space-between">
  We may also need to further investigate your complaint, this might mean we need to speak with employees involved, check system notes, current process, or listen to call recordings before we can action the complaint.
</div>

<div class="session-cookies space-between">
  We aim to have your complaint resolved to your satisfaction at the earliest opportunity, and within 4 weeks from the date we receive your complaint. We will keep you updated on the progress of the measures being taken to resolve the complaint.
</div><div class="session-cookies space-between">
  Once all information has been considered you will be contacted by letter or telephone to confirm whether the company accepts, either completely, or to some degree your complaint.

<div class="session-cookies space-between">
  Where applicable, you will be advised of what steps the company will take to put right the complaint and ensure that the same problem does not reoccur.

</div>
<div class="session-cookies space-between">
  Where more detailed investigation is required the process may take longer than 4 weeks. In any event, we will respond to your complaint in full within 8 weeks.
</div>
<div class="sub-heading">
  Financial Ombudsmen Service
</div>
<div class="session-cookies space-between">
  If you are not satisfied with our final response, you may refer the matter to the Financial
  Ombudsman Service, which can be contacted on the below;
</div>
<div class="session-cookies space-between">
  Telephone: 0800 023 4 567/ 0300 123 9 123</div>
  <div class="session-cookies space-cookies">
    20-22 Wenlock Road, London,<br>England, N1 7GU<br><a href="mailto: complaints@lifezy.co.uk" class="link-color"> complaints@lifezy.co.uk</a>
    </div><br>
<div class="session-cookies space-between">
  Please note that you have six months from the date of our final response to refer the matter to the Financial Ombudsman Service. We hope that you will accept our decision. If this should not be the case, you remain free at all times to seek an independent form of advice.</div>

<div class="cookies-explain">Contact Us</div>
<div class="session-cookies space-cookies-2">You can write to:  Lifezy</div>
<!---<div class="cookies-explain">Further Reading</div>-->
<div class="session-cookies space-between">
  20-22 Wenlock Road, London,<br>England, N1 7GU<br>
</div>
<div class="session-cookies space-between">
  You can email:<a href="mailto:complaints@lifezy.co.uk" class="link-color"> complaints@lifezy.co.uk</a>
</div>
</div></div></div>
</div></div>

<!-- Modal Terms & Conditions  -->
<div class="modal fade" id="modaltandc" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header custom-modal-header">
        <h5 class="modal-title custom-modal-title" id="staticBackdropLabel">Terms & Conditions</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">
            <img src="../{{imgSrc}}closeiconcross.svg" alt="image not found">
          </span>
        </button>
      </div>
      <div class="modal-body custom-modal-body">
        <div class="section-container">
          <p class="para">Our Terms and Conditions together with our Privacy Policy and Cookies Policy establish a legal
            agreement
            which sets out our relationship with you. They explain our and your responsibilities, and outline how you
            can use our service and access our products. Please read them carefully as you will be agreeing to them in
            order to receive our unique service.</p>
          <p class="para">Lifezy is a trading name of Score Genius Technology Limited, any references to Lifezy are
            references to
            Score Genius Technology - Company Registration Number:09463062. Score Genius is authorised and regulated by
            the Financial Conduct Authority under reference FRN 830950.</p>
          <p class="para">You may wish to check our registration on the Financial Services Register by visiting <span
              class="blue-txt">www.fca.org.uk/register</span> or by contacting the FCA on 0800 111 6768 (freephone) or
            0300 500 8082 or by emailing <span><a class="blue-txt" href="mailto:consumer.queries@fca.org.uk">consumer.queries@fca.org.uk</a></span>.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Data protection</h3>
          <p class="para">Our data controller registration number is ZA244537. We encourage you to check our
            registration on the Data
            Protection Public Register by visiting <a class="blue-txt" href="https://ico.org.uk/esdwebpages/search" >
              https://ico.org.uk/esdwebpages/search</a>.</p>
          <p class="para">References in these Terms and Conditions and on our platforms or communications to “we”, “our”
            or “us” are
            references to Score Genius Technology. ‘We’ may also mean anyone that might take over our business, or that
            we transfer our business to in the future. In a few places, ‘we’ might refer to one of our third party
            service providers or a subcontractor, however this should be clear from the context. References to “you” and
            “your” means each natural or legal person who uses our platforms and services.</p>
        </div>
        <div class="section-container">
          <p class="bold-para">All references to our website include references to our mobile application.</p>
          <ul>
            <li> The services we provide are on an ‘as is’ basis</li>
            <li> We and our third party providers have no influence or control over the information provided by Experian
            </li>
            <li> We and our third party providers have no control over the accuracy of the information provided by
              Experian</li>
            <li> Though we endeavour to ensure it is, we are unable to guarantee that the information on our website, in
              our
              mobile app, or on posts to external sites is current, up-to-date or accurate and we offer no guarantee
              that it is</li>
            <li>We accept no liability for omissions or inaccuracies and we urge you double check and confirm the
              accuracy of
              any information before you act on it</li>
          </ul>
        </div>
        <div class="section-container">
          <p class="para">These Terms specifically establish your agreement with us relating to your use of our website,
            apps and
            services, they do not create any relationship or agreement between you and our third party service
            providers, affiliates, any other company, or person.</p>
          <p class="para">Lifezy is an independent credit broker. We’ll do a credit check for you and provide you with
            your credit
            report for free. This is an integral part of our service. If you purchase a product through our service, we
            will earn a commission from the product’s provider. Please be assured, that we never offer you products based
            on the level of commission we receive and we list our offers on your eligibility and the financial benefits
            to you.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Our Services</h3>
          <p class="para">We aim to help you take control over your own finances by helping you to access your credit
            report and
            credit score from Experian. We let you do this free of charge. We procure this information for you, as you
            could also do. By using our services you agree that we and Experian shall be authorised and given permission
            to access your credit report. In order to procure a credit report and give you your credit score you will need to pass an
            identity authentication process. This is for security purposes. Experian will try to assist you if you fail
            the identity authentication process, but we and they do not need to inform you of any reasons why you did
            not pass or comply.<br>This information is procured through ‘soft searching’ your credit records.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Soft searching</h3>
          <p class="para">When you search for credit offers on our website or mobile app, we or our service providers
            will record a
            ‘soft credit search’ on your credit file, but rest assured this record will be invisible to other lenders,
            meaning that the soft search will not affect won’t affect your credit score as any a lender’s decision
            whether to lend or not will not be recorded.</p>
          <p class="para">If you apply for a financial product, through our platforms or otherwise, the lender will most
            likely
            conduct a hard credit search before accepting your application. Hard searches normally stay on your credit
            record for 12 months. Having multiple hard searches performed on your credit report could affect a financial
            service provider’s decision to lend to you.</p>
        </div>
        <div class="section-container">

          <p class="para-bold">How often will a “soft credit search” be performed for your Lifezy account?</p>

          <p class="para">By using our services you agree that your records and, where applicable, your financial
            associates – e.g.
            someone you have a joint mortgage or joint loan with - will be searched:</p>
          <ul>
            <li>As part of verifying your identity, we will carry out an authentication search against your credit
              information. This
              search will be carried out once at the time you register for our services.</li>
            <li>Whilst you remain a user of our services, we or our third party service providers will carry out
              searches against
              your credit information and, where applicable, your financial associates’ credit information in order to
              provide you
              with your credit score, credit report and relevant products.</li>
          </ul>
        </div>
        <div class="section-container">
          <p class="para">We will hold your details and records on our systems and make this credit information
            available to you
            through our website and app. If there has been a change to information in your credit score or credit
            report, we may notify you by sending you an email or through a push notification.</p>
          <p class="para">We will undertake analysis and profiling of your credit information and, where applicable,
            your financial
            associates’ credit information (and any other information provided by you or on your behalf). This is to
            assist you in being better informed about, understand and manage your credit position, and in order to
            identify and inform you of credit products that are likely to be suited to your circumstances or which we
            collect about you through our website, apps and your mobile devices. We will use your credit information and
            any other data you provide or make available to us for the purposes set out in these Terms, including our
            Privacy Policy.
          </p>
          <p class="para">We may also use your information (and, where applicable, that of your financial associates) to
            identify
            whether you may benefit from additional guidance concerning your credit score and steps you can take to
            improve your score.</p>
          <p class="para">We may share your information with third party service providers, such as Credit Reference
            Agencies,
            financial services providers and other advertisers, in accordance with our Privacy Policy and your account’s
            privacy settings. Certain third party product and services providers provide us with their own services to
            assess your suitability for particular products.</p>
          <p class="para">If you would prefer not to share your information with such third party service providers, you
            can opt out
            of this at any time. Please note that if you opt out of this, we won’t be able to show you offers that are
            specifically suitable for you.</p>
          <p class="para">The offers you receive will be from other financial services providers. You don’t have to
            accept any
            offers. Our intention is that you are only offered products that are advantageous to you and you are likely
            to be accepted for. Some of them may save you money, but others may not. Whether to accept any offer is your
            decision. You have all the information about your individual financial situation. We are not providing you
            with financial advice. If you have any queries or questions, please contact an independent financial
            advisor, or a debt advice agency such as</p>
        </div>
        <div class="section-container">
          <p class="para-bold">Making an application for lending from a third party
          </p>
          <p class="para">Third party lenders will use their own criteria in making decisions about you. Any decisions
            you make on
            the basis of your credit score or credit report, or any other information we provide or make available to
            you, are purely your own choice. Please ensure that you have fully read and understand any relevant
            information, such as the credit provider’s terms and conditions, which provide full details of the risks
            involved with any decision you make. You may wish to consider obtaining professional or specialist advice
            before taking, or refraining from taking, any action on the basis of the content on our website or any other
            information we provide or make available to you.</p>
        </div>
        <div class="section-container">

          <p class="para-bold">Product Comparison Services</p>

          <p class="para">Lifezy offers a price comparison service that can be used to find out whether you could save
            money on some
            types of insurance, or on a range of other services or products.
            We may receive a commission from product taken in our marketplace.</p>
        </div>
        <div class="section-container">

          <p class="para-bold">Your responsibility</p>

          <p class="para">In order to get a quote through our price comparison service, you will need to answer some
            questions on our
            site needed by all the different product or service providers and any intermediaries they may use. The
            answers you give will determine the products or services available to you and the amount you will pay. You
            must get permission from any other people whose details you propose to use in requesting a quote. If you
            submit another person’s details, you are confirming to us that you have their permission to do so, and that
            they understand how their information will be used.</p>
          <p class="para">You are solely responsible for ensuring that all information you provide us with is true,
            accurate and
            complete and you agree to the assumptions we make. If you do not provide accurate information this could
            lead to your selected product being invalid and claims may not be paid. You agree that we cannot be held
            responsible if your selected product is invalid as a result of you providing incorrect information, to us or
            your selected provider.</p>
          <p class="para">As we work with a large number of providers it is important that you read the provider’s terms
            and
            conditions, and that you read all documents issued to you by your selected provider and ensure that you are
            aware of any levels of cover, financial limits and any other terms that apply. If any information is not
            correct, it is your responsibility to identify the mistake and correct this with the provider directly. To
            access our services, you will need to create a username and password. You agree to keep confidential the
            password and any other login details you use to access our services or engage in any disputes process and
            not to disclose such passwords and other login details to anyone else. If you know or suspect that someone
            else has your login details and password you must contact us immediately and change your password. We will
            not be responsible for any activity performed using your login details unless we have acted negligently or
            in breach of these Terms.</p>
        </div>
        <div class="section-container">

          <p class="para-bold">Price delivery</p>

          <p class="para">Companies change their prices and amend their rates on a regular basis. Any price quoted to
            you will be
            accurate at the time of issue, and may change if your quote is resubmitted.</p>
        </div>
        <div class="section-container">

          <p class="para-bold">Your privacy.</p>

          <p class="para">Please read our Privacy Policy for details of how we use your personal information. If you ask
            us to delete
            any of your information in response to a Notification, we will comply with your request, in accordance with
            our <span class="blue-txt">Privacy Policy</span>, solely to the extent that it relates to information
            processed by us in connection with the Lifezy services. If we do delete or restrict access to any of your
            information at your request, we may not, as a result, be able to continue to provide some or all of our
            services to you. We also have the right to continue using your information if our holding and use of it is
            necessary for compliance with our legal obligations.</p>
        </div>
        <div class="section-container">

          <p class="para-bold">Who can be our customer?</p>

          <ul>
            <li>UK resident</li>
            <li>Over 18 </li>
            <li>A genuine person who is accessing our services for your personal use only and on your own behalf.
              (Please keep
              logins private and confidential, as you are responsible for any use of your account, whether it is
              permitted by you
              or not.)</li>
          </ul>
          <p class="para">We reserve the right to refuse to provide services to a new customer or stop the provisions of
            services to
            existing customers, at any time and at our sole discretion. We might do this if:</p>
          <ul>
            <li>We believe you have breached these Terms and Conditions</li>
            <li>We think your activities on our website or mobile app are illegal</li>
            <li>We have not been able to verify your identity</li>
            <li>You’re rude, aggressive or threatening to our staff</li>
            <li>We find or believe you have breached our Acceptable Use Policy</li>
          </ul>
        </div>
        <div class="section-container">

          <p class="para-bold">Our Acceptable Use Policy
          </p>

          <p class="para">Please carefully read our Acceptable Use Policy. We retain the right to stop providing you
            services should
            you breach it and we may take action, including legal action if need be. As detailed in our Privacy Policy
            if we believe you have behaved unlawfully, we can disclose your identity, activities and pass on other
            information thatwe hold about you to law law enforcement agencies.</p>
          <p class="para">We ask that when you use our services you do not do the following:</p>
          <ul>
            <li>Provide us with an email that is not yours or that you do not solely control</li>
            <li>Fail to comply with local, national or international law or regulation</li>
            <li>Deceive or defraud, or participate activity that has a fraudulent purpose or effect</li>
            <li>Create or use a false identity</li>
            <li>Access or interfere with another person’s account or information</li>
            <li>Impersonate another person</li>
            <li>Utilise our services for your own or another person’s business purposes</li>
            <li>Harm or corrupt, or to attempt harm or corruption to any other person in any way</li>
            <li>Make any unsolicited or unauthorised communications, including of advertising or promotional material
              (“spam”)</li>
            <li>Make or attempt to make unauthorised access to damage, disrupt or interfere with any part of our
              website,
              systems, equipment, software or networks on or through which our website is stored or operated</li>
            <li>Modify, adapt, decipher, decompile, disassemble or reverse engineer any of the software comprised in or
              in any
              way making up a part of our website or services provided by our website</li>
            <li>Scrape, reproduce, duplicate, copy, modify, distribute, publish, resell or transmit any element
              comprised in our
              website, including any software comprised in it, the content or the services provided through it</li>
            <li>To use any technology or processes of any kind to access, acquire, copy, monitor, navigate or search our
              website
              or any portion of it (including the use of data mining, robots or similar data gathering and extraction
              tools)</li>
           
          </ul>
        </div>
        <div class="section-container">
          <p class="para">You must not, and you must not encourage or allow another person to:</p>
          <ul>
            <li>Knowingly transmit any data, send or upload any material that is corruptor contains viruses, Trojan
              horses,
              worms, logic-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer
              code designed to adversely affect the operation of any computer software or hardware or perform any act
              that
              would cause our website to become unavailable for use by others (including via any form of denial-of
              service
              attack)</li>
            <li>Access without authority, interfere with, damage or disrupt any part of our website, any software, the
              server or
              other equipment or network used in the provision of our website (including where owned or used by any
              third
              party)</li>
          </ul>
        </div>
        <div class="section-container">
          <p class="para">We may monitor your use of our services, and your activity for security purposes, so we can
            ensure it is in
            line with our Acceptable Usage Policy. </p>
          <p class="para"> You must fully cooperate with us where any law enforcement authorities, regulators or court
            order requests
            or directs us to disclose the identity or locate anyone posting any material or using our website or our
            services in breach of our Acceptable Use Policy.</p>
        </div>
        <div class="section-container">
          <p class="para-bold">Linking to our website</p>
          <ul>
            <li>You may link to our home page, provided you do so in a way that is fair and legal and does not damage
              our
              reputation or take advantage of it</li>
            <li>You must not establish a link in such a way as to suggest any form of association, approval or
              endorsement on
              our part where none exists</li>
            <li>You must not establish a link to our website in any website that is not owned by you or that does not
              comply in
              all respects with the content standards set out in our Acceptable Use Policy</li>
            <li>Our website must not be framed on any other site, nor may you create a link to any part of our website
              other
              than the home page</li>
            <li>We reserve the right to withdraw linking permission without notice</li>
          </ul>
        </div>
        <div class="section-container">
          <h3 class="h-title">Content Standards</h3>
          <p class="para">These content standards apply to any and all material which you may contribute to our website
            and to any
            interactive services associated with it. Contributions must:</p>
          <ul>
            <li>Comply with applicable law in the UK and in any country from which they are posted</li>
            <li>Accurately state facts</li>
            <li>Be genuinely held opinions</li>
          </ul>
        </div>
        <div class="section-container">
          <p class="para">These content standards apply to any and all material which you may contribute to our website
            and to any
            interactive services associated with it. Contributions must:</p>
          <ul>
            <li>Contain any material which is defamatory of any person</li>
            <li>Contain any material which is obscene, offensive, hateful or inflammatory</li>
            <li>Promote sexually explicit material, violence or any illegal activity</li>
            <li>Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age
            </li>
            <li>Infringe any copyright, database right or trade mark of any other person</li>
            <li>Be likely to deceive any person</li>
            <li>Be made in breach of any legal duty owed to a third party (for example be confidential information)</li>
            <li>Be threatening, abuse or invade another’s privacy, or cause annoyance, inconvenience or needless anxiety
            </li>
            <li>Be likely to harass, upset, embarrass, alarm or annoy any other person</li>
            <li>Be used to impersonate any person, or to misrepresent your identity or affiliation with any person</li>
            <li>Give the impression that they emanate from us, if this is not the case</li>
            <li>Advocate, promote or assist any unlawful act including copyright infringement or computer misuse</li>
          </ul>
        </div>
        <div class="section-container">
          <h3 class="h-title">Making Changes to our Terms and Conditions or our Services</h3>
          <p class="para">We may need to change these Terms, our website, apps and our services and reserve the right to
            do this at
            any time. For changes that affect your rights or obligations under this agreement, if you have registered
            your email with us, we will notify you by email, or by posting a notice on our website or app or through the
            services, or in any other way we believe appropriate. Your continued use of our website, apps or our
            services will confirm your acceptance of the revised terms. For changes that do not affect your rights or
            obligations under this agreement and a simply an update of information, we may not notify you directly.
            Please ensure you check this page regularly for any updates made here.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Accuracy</h3>
          <p class="para">We don’t check the information we receive from you or third parties for accuracy or
            completeness.</p>
          <p class="para">If you believe any of the information we have provided to you or published on our website is
            incorrect,
            please let us know as soon as possible by contacting <span>support@lifezy.co.uk</span>.</p>
          <p class="para">We don’t assume responsibility for the timeliness, accuracy, deletion, non-delivery or failure
            to receive
            or store any user information, communications or personalisation settings.</p>
          <p class="para">We may change the design, content or functionality of our apps, website or any other platform
            that gives
            access to our services at any time. Should it be out of date we are not obligated to update our content at
            any time. We are unable to guarantee that our website or apps or any content on them will be available,
            uninterrupted or error free at all times.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Intellectual Property Rights</h3>
          <ul>
            <li>Intellectual property rights in our website and apps in the material published on them and in their
              component
              systems are owned by, licensed or sub-licensed to us. All content on our website and apps is subject to
              copyright
              with all rights reserved</li>
            <li>Images, trade marks and brands are protected by intellectual property laws and may not be reproduced
              or
              appropriated in any manner without permission of their respective owners</li>
            <li>You must not remove any acknowledgement that we or any of our contributors is the author of any
              website
              content or any content we provide to you as part of the services</li>
            <li>You may download or print or screen grab content or individual sections or pages of our website or
              apps for your
              personal use and information only, provided that any such copy has attached to it the relevant proprietary
              notices
              and/or disclaimers. Any material downloaded or otherwise obtained through our website or apps is done at
              your
              own discretion and risk and you are solely responsible for any damage to your computer system or device or
              loss
              of data that results from the download of any such material</li>
            <li>You must not modify, adapt, copy, download or post material from our website or apps nor store any
              part of our
              website or apps in any other website or app or include it in any public or private electronic retrieval
              system</li>
            <li>You must not use any illustrations, photographs, video or audio sequences or any graphics separately
              from any
              accompanying text or explanation</li>
            <li>If you print off, copy, download or post to social media any part of our website or apps in breach of
              these Terms
              you must, at our option, return, destroy or procure the deletion of any copies of the materials you have
              made</li>
          </ul>
        </div>
        <div class="section-container">
          <h3 class="h-title">License</h3>
          <p class="para">You licence us to use any information, data, materials or other content (‘Content‘) you
            provide to us
            through our website or that we obtain on your behalf as part of the services we provide and you agree that
            we can use, modify, display, distribute and create new material using the Content so that we may provide our
            services to you and for any other purposes set out in these Terms, including our Privacy Policy. By
            submitting Content you confirm and agree that the owner of that Content has expressly agreed that, without
            any particular time limit, and without the payment of any fees, we may use the Content for the purposes set
            out in these Terms.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Limitation of our Liability</h3>
          <p class="para">Please read the provisions of this section carefully, as they exclude or limit our liability
            for any losses
            suffered by you in connection with your use of our services. Nothing in these Terms excludes or limits our
            liability for death or personal injury arising from our negligence, or our fraud or fraudulent
            misrepresentation, any duties or liabilities that we owe to you under the Financial Services and Markets Act
            2000 or the rules of the Financial Conduct Authority, nor any other liability that cannot be excluded or
            limited under applicable law.</p>
          <p class="para">
            To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which
            may apply to our website and apps, any content on them or services accessed through them, whether express or
            implied. We will not be liable to you or any third party for any loss or damage, whether in contract, tort
            (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in
            connection with:
          </p>
          <ul>
            <li>Use of, inability to use or unavailability of, our website (or any part of it), websites linked to it,
              or our services</li>
            <li>Use of any products, data, information or services accessed or obtained or messages received or
              transactions
              entered into, through or from the services obtained or accessed through our website or apps including use
              of or
              reliance on any content displayed on or made available through our website or apps</li>
            <li>Unauthorised access to or alteration of your transmissions or data, or</li>
            <li>Any inaccuracy or incompleteness of any information received by you or by us through our website or apps
              or as
              part of our services</li>
            <li>We shall only be responsible for loss or damage you suffer that is a foreseeable result of our breach of
              these
              Terms or our negligence. Loss or damage is foreseeable if it is an obvious consequence of our breach or if
              they
              were contemplated by you and us at the time you accepted these Terms</li>
          </ul>
          <p class="para">We are not responsible for any products or services provided by any other third parties. Those
            products are
            governed by separate terms and conditions of supply. You agree that we are not responsible or liable for any
            loss or damage you may suffer or incur in connection with any such product or services, or the terms and
            conditions applying to any contract you enter into with any third party in relation to any product or
            services, or for any acts, omissions, errors or defaults of any third party in connection with any product
            or services or the applicable terms and conditions.
          </p>
          <p class="para">Where we have indicated your likelihood of success with respects to products matched to your
            profile, or
            where we have provided indications of how much money you may save, these are provided for information
            purposes only and should not be relied upon when making decisions. We do not accept any liability or provide
            any guarantee with respects to your eligibility for or success in obtaining products matched to you or
            advertised on our website.</p>
          <p class="para">Although we take all reasonable steps to protect our systems using virus checking software,
            please note
            that any content downloaded from our website or apps is at your own risk. We do not accept liability for any
            loss or damage that you suffer as a result of any distributed denial-of-service attacks, computer viruses,
            Trojan horses, worms, software bombs or other damaging items resulting from your use of our website or apps.
            You are responsible for protecting your computer systems and other devices from exposure to viruses by the
            use of anti-virus software, firewalls and any other technical measures necessary. If you download our app or
            other digital content provided by us and you suffer damage to your device or other digital content you own
            because we have not exercised reasonable care and skill in creating such digital content, you may be
            entitled to compensation or repair at our expense pursuant to your statutory rights.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Ending this Agreement</h3>
          <p class="para">We reserve the right at any time to terminate your agreement with us and your use of our
            website or any
            products or services contained in or obtained through our website, with or without cause, immediately upon
            our giving you notice in writing to the email address provided.</p>
          <p class="para">Please note that if you withdraw consent to the processing of your personal information,
            access to our
            website, apps and the associated services will be suspended or terminated. We may keep records or data
            derived from your personal information in accordance with our Privacy Policy.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">General</h3>
          <p class="para">The headings used in these Terms are for convenience only and shall not affect their
            interpretation.</p>
          <p class="para">In these Terms, unless specified otherwise, use of the singular includes the plural, use of
            any gender is
            deemed to include every gender and any reference to a person includes a corporation, a partnership and any
            other body or entity, and vice versa. Use of the words, includes or including or similar words or phrases
            means without limitation and the use of these or similar words or phrases shall not limit the meaning of the
            general words.</p>
          <p class="para">We shall not be liable for any delay or failure to provide our services or perform any
            obligation under
            these Terms if the delay or failure is caused by circumstances beyond our reasonable control.
          </p>
          <p class="para">
            Nothing in these Terms is intended to confer on any person any right to enforce any term of these Terms
            which that person would not have had but for the Contracts (Rights of Third Parties) Act 1999.
          </p>
          <p class="para">Each of the provisions of these Terms (or part of any provision) is severable. If any such
            provision is or
            becomes illegal, invalid or unenforceable in any respect under the law of any jurisdiction that shall not
            affect the legality, validity or enforceability of the other provisions of these Terms. The provision itself
            shall apply with the minimum modification necessary to make it legal, valid and enforceable. Failure or delay
            by us in enforcing these Terms shall not be a waiver of that or any other provision of these Terms.</p>
          <p class="para">You may not assign any of your rights or obligations under these Terms. These Terms (including
            our Privacy
            Policy and Cookies Policy) constitute the whole agreement and understanding between you and us in relation
            to the use of our services. By using our website or our services you consent and agree to the processing of
            your personal information and our use of cookies in accordance with them. Except in case of fraud, all
            previous agreements, understandings, undertakings, representations, warranties, promises and arrangements
            between you and us relating to the subject matter of these Terms or your use of our website or our services
            are superseded, except as otherwise expressly stated in these Terms.</p>
          <p class="para">You agree that any notices and other communication may be given by us by email or posted on
            our website and
            this will comply with any legal requirement for such communications to be in writing. You also agree that
            any notice or other communication made in such manner will be deemed received by you 24 hours after email or
            posting.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Disputes and Laws</h3>
          <p class="para">These Terms and any dispute or claim arising out of or in connection with them or their
            subject matter or
            formation (including non-contractual disputes or claims) shall be governed by and construed in accordance
            with English law and shall be subject to the exclusive jurisdiction of the English courts.</p>
          <p class="para">You should also remember that these aren’t a complete list of all your or our rights or
            responsibilities,
            as there might be others under general law. If you want more information about your general rights as a
            consumer, contact a consumer advice organisation, such as the Citizens’ Advice Bureau.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Contact us</h3>
          <p class="para">
            If you have any questions about or feedback on our Terms and Conditions or wish to make a complaint about
            our services, please inform us by emailing <span class="blue-txt">support@lifezy.co.uk</span> If you have made a
            complaint about our services
            and we have provided a final response which is not to your satisfaction or you have not received a response
            within eight weeks of making your complaint, you can ask the Financial Ombudsman Service to investigate. The
            contact details for the Financial Ombudsman Service can be <a class="blue-txt" href=" https://www.financial-ombudsman.org.uk/"><span class="blue-txt">found here</span></a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal privacypolicy -->
<div class="modal fade" id="privacypolicy" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header custom-modal-header">
        <h5 class="modal-title custom-modal-title" id="staticBackdropLabel">Privacy Policy</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">
            <img src="../{{imgSrc}}closeiconcross.svg" alt="image not found">
          </span>
        </button>
      </div>
      <div class="modal-body custom-modal-body">
        <div class="section-container">
          <p class="para">Lifezy is a trading name of Score Genius Technology Limited, any references to Lifezy are
            references to Score
            Genius Technology, Company Registration Number: 09463062. SGT is authorised and regulated by the Financial
            Conduct Authority under reference FRN 830950.</p>
        </div>
        <div class="section-container">
          <p class="para">References in this Privacy Policy and on our platforms or communications to “we”, “our” or
            “us” are
            references to Score Genius Technology. References to “you” and “your” means each natural or legal person who
            uses our services.</p>

        </div>
        <div class="section-container">
          <p class="para">All references to our website include references to our mobile application.
          </p>
        </div>
        <div class="section-container">
          <p class="para">
            In order for us to provide our unique Personal Finance Management service to you Lifezy gathers data from you
            or that you need to give data to us. Protecting your privacy and information is of the utmost importance to
            us. Before using our app or web platform, or registering with us, please take the time to read our Privacy
            Policy in detail. It’s crucial you’re informed about your privacy when using Lifezy. Our Privacy and Cookies
            Policies, and our Terms and Conditions are compiled to demonstrate our practices and procedures regarding
            how we gather and undertake the processing of your data.
          </p>
        </div>
        <div class="section-container">
          <p class="para">When using our services via Android/iOS app and our website, you are thereby accepting and
            consenting to
            our practices and procedures described in this Privacy Notice. The data controller of your information is
            Score Genius Technology of 419 Richmond Rd, Twickenham TW1 2EX.</p>
        </div>
        <div class="section-container">
          <p class="para">Our data controller registration number is ZA244537. Our registration on the Data Protection
            Public
            Register can be found at <a href="https://ico.org.uk/esdwebpages/search" ><span class="blue-txt">https://www.ico.org.uk/esdwebpages/search</span></a> .</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Privacy Policy</h3>
          <ul>
            <li>The information we collect</li>
            <li>How we use the information</li>
            <li>Who we may share your data with</li>
            <li>Energy helpline / Fundraising Innovations Ltd</li>
            <li>What’s the legal basis for us using this information</li>
            <li>How long we keep your information for</li>
            <li>How we protect your information</li>
            <li>What direct marketing will you receive</li>
            <li>How you can change your direct marketing preferences</li>
            <li>Links to and from third party websites</li>
            <li>Automated Decision Making</li>
            <li>Your rights</li>
            <li>Changes we make to our Privacy Policy</li>
            <li>Who are the Information Commissioners Office (ICO)</li>
            <li>Your right to lodge a complaint with the ICO</li>
            <li>Contact us</li>
          </ul>
        </div>
        <div class="section-container">
          <h3 class="h-title">1. The information we collect</h3>
          <b>
            <p class="bold-para">We collect information that:</p>
          </b>
          <ul>
            <li>You give us when you sign up with us</li>
            <li>We obtain from your use of our website and app</li>
            <li>We acquire information from Experian and their subsidiary FraudNet</li>
            <li>We receive that other third parties pass us about you</li>
            <li>We obtain through telephone communications</li>
            <li>We obtain from cookies (Please refer to our cookies policy)</li>
          </ul>
          <p class="para">We may collect and process the following data about you:</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Information you give us.</h3>
          <p class="para">You will give us information about yourself during your use our web platform, app and if you
            communicate
            with us. Also if you tell us about a problem, complete a survey or answer questions, give us feedback or
            participate in forums.</p>
          </div>
          <div class="section-container">
          <span class="para">
            The information you give us may include but is not limited to:</span>
          <ul>
            <li>Title</li>
            <li>Name</li>
            <li>Contact details</li>
            <li>Date of birth</li>
            <li>Income</li>
            <li>Monthly expenditure</li>
            <li>Property value</li>
            <li>Deposit</li>
            <li>Mortgage details</li>
            <li>Energy and gas tariff details</li>
            <li>Address history</li>
            <li>Employment info</li>
            <li>Bank Accounts (Current and Savings)</li>
            <li>Monthly rental payments or income</li>
            <li>Driving license details</li>
          </ul>
        </div>
        <div class="section-container">
          <h3 class="h-title">Special categories of data.</h3>
          <p class="para">If you access some of our services (e.g. credit; insurances) we may ask you for or we may
            obtain special
            categories of data:</p>
          <ul>
            <li>Claims history</li>
            <li>Health Data</li>
            <li>Criminal Convictions</li>
            <li>County Court Judgements</li>
          </ul>
        </div>
        <div class="section-container">
          <span class="bold-para">Information we obtain from your use of our website and app
          </span><br>
          <p class="para">Each and every time you use our app or our website we may collect the following information:
          </p>
        </div>
        <div class="section-container">
          <p class="para"> <span class="bold-para">Information about your visit, </span>including the full Uniform
            Resource Locators (URL) clickstream to, through and from our website which
            includes date and time; products you viewed, products searched for; page response times, download errors,
            length of visits to certain pages, page interaction information (such as scrolling, clicks, and
            mouse-overs) and methods used to browse away from the page and any phone number used to call our customer
            service number.
          </p>
        </div>
        <div class="section-container">
          <p class="para"><span class="bold-para">Technical information, </span> including your login information, the
            Internet protocol (IP) address, time zone setting, operating system,
            browser type, version and platform.</p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para">Information we collect from Credit Reference Agencies on your behalf.</span><br>
            If you register to use our services, we will obtain your credit score and credit report from the leading
            Credit Reference Agency - Experian.
          </p>
        </div>
        <div class="section-container">
          <span class="bold-para"> Information we receive that other third parties pass us about you</span>
          <br>
          <p class="para">We work with a number of third parties and they may pass us information about you. These third
            parties
            include but are not limited to business partners, advertising networks, search information providers,
            service providers, analytics providers and social media. They may pass us your credit report, products you have
            taken out or been refused, if you’ve used other products, and other similar details. We may also receive
            information about you if you use any of the websites we operate or the other services we provide.</p>
        </div>
        <div class="section-container">
          <span class="bold-para">Information from telephone calls and other communications </span>
          <br>
          <p class="para">We may collect information from telephone calls and other communications between us, which we
            may monitor
            or record.</p>
        </div>
        <div class="section-container">
          <span class="bold-para">Information collected from cookies.</span>
          <br>
          <p class="para">We collect information about your use of our website from cookies. Please refer to our Cookies
            Policy for
            more on how we use cookies and how turn them off or decline them.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">2. How we use the information</h3>
          <p class="para">The information we hold in connection to you to communicate with you, provide you our services
            and improve
            on those services. We use your anonymized information for profiling, analysis and research</p>
        </div>
        <div class="section-container">
          <b><span class="bold-para">Information we collect about you based on your use of our website.</span></b>
          <p class="para">We will use this information:</p>
          <ul>
            <li>To make recommendations about products or services that may be of interest</li>
            <li>To improve our services</li>
            <li>To understand the impact of the advertising we show you, and to ensure the advertising is relevant
            </li>
            <li>For internal operations – testing, data analysis, troubleshooting, research, statistical and survey
              purposes</li>
            <li>In our efforts to prevent fraud and keep our website safe and secure</li>
          </ul>
        </div>
        <div class="section-container">
          <b><span class="bold-para">Information you give us, or that we collect on your behalf from Experian, or other
              third parties will
              be used to:</span></b>
          <ul>
            <li>For our identity authentication process to verify your identity</li>
            <li>Run your account and communicate with you by telephone, mail, email, text (SMS) message, or push
              notification</li>
            <li>Provide you with information, services and products</li>
            <li>For profiling and analysis of your credit information to inform you of credit products that we believe
              are likely to
              be of interest to you and are suitable to your credit profile</li>
            <li>Notify you about changes to our services</li>
            <li>Guarantee that content on our platforms is presented in the most effectual method for you and your
              device</li>
            <li>Should you have given your consent for us to market other services and products to you</li>
            <li>Aggregate it on an anonymous basis with other data for data analytical and reporting purposes</li>
            <li>To improve our services</li>
          </ul>
        </div>
        <div class="section-container">
          <span class="bold-para">Information we receive from other sources.</span>
          <p class="para"> We may use information from other source with information you give to us for the purposes
            outlined above.
          </p>
        </div>
        <div class="section-container">
         <span class="bold-para">Recording telephone calls and other communications.</span>
          <p class="para">We may use telephone recordings and/or other communications to improve our services, verify
            your
            instructions to us, analyses and assessment, as well as for training and quality purposes. For investigation
            of any complaint you may make, or as evidence in any dispute between you and us.</p>
          </div>
        
        <div class="section-container">
         <span class="bold-para">What we will never do.</span>
          <p class="para">We will never sell your information to third parties without your consent. Lifezy operates a ‘by
            consent’
            policy to enable faster optimised applications for products.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">3. We may share your data with:</h3>
          <p class="para">Other members of our group and with other third parties, such as our service providers,
            advertisers,
            Experian, and Fraud Prevention Agencies. We may need to share your data to others to enable them to provide
            you with the information, products and services and you request. Your data may be disclosed to the other
            third parties as listed below. These third parties process your information under our instructions. To verify
            your identity, prevent fraud and money-laundering, information we have collected from you will be shared
            with fraud prevention agencies who will use it.</p>
          </div>
          <div class="section-container">
          <p class="para">If fraud is detected, you could be refused finance, services, or employment.</p>
        </div>
        <div class="section-container">
          <b><span class="bold-para">Subprocessors</span></b>
          <p class="para">Under GDPR, a sub-processor is any business or contractor customer information may pass
            through as a
            side-effect of using Lifezy’s services. We use partners for some business processes that are critical to our
            customers having a quality experience. Here is a list of our sub-processors who we may share your
            information with:</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Sub-processors</h3>
          <p class="para">AWS – Cloud Hosting Provider</p>
          <p class="para">MailChimp – Customer Relationship Management Tool</p>
          <p class="para">Experian – Data Provider for Credit Score and run part of the Market Place</p>
          <p class="para">True Layer – Access to Open Banking</p>
          <p class="para">Energy Helpline – Utilities Swaps</p>
          <p class="para">Amazon Web Services</p>
          <p class="para">Cloud Service</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">Group company.</h3>
          <p class="para">We may share your personal information with any member of our group, which means our
            subsidiaries, our
            ultimate holding company and its subsidiaries.</p>
        </div>
        <!---<div class="section-container">
          <p class="para">Including:</p>
          <p class="para"> Score Genius Technology</p>
        </div>--->
        <div class="section-container">
          <div class="table-container">
            <div class="table-top">
              Groups of recipients within the specific industry sectors listed
            </div>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="th-first">Industry Sector</th>
                  <th>Examples include, but not limited to:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="td-first">Automotive</td>
                  <td>e.g. car manufacturers, test drives, servicing, breakdown cover</td>
                </tr>
                <tr>
                  <td class="td-first">Charity</td>
                  <td>e.g. animal welfare, disaster relief, children’s medical, elderly, environmental, health</td>
                </tr>
                <tr>
                  <td class="td-first">Leisure</td>
                  <td>e.g. food & drink, events, museums, cinema</td>
                </tr>
                <tr>
                  <td class="td-first">Financial products</td>
                  <td>e.g. banking, credit cards, investments, loans, mortgages, pensions</td>
                </tr>
                <tr>
                  <td class="td-first">Insurance</td>
                  <td>e.g. car, home, life, medical, pet, income protection, travel, warranty products</td>
                </tr>
                <tr>
                  <td class="td-first">Health / Mobility</td>
                  <td>e.g. fitness, beauty, opticians, hearing, care homes, mobility</td>
                </tr>
                <tr>
                  <td class="td-first">Home Improvements</td>
                  <td>e.g. house moving, blinds & curtains, insulation, boilers,conservatories, doors & windows,
                    extensions, gardens,solar panels</td>
                </tr>
                <tr>
                  <td class="td-first">Wills</td>
                  <td>e.g. writing new Wills or reviewing existing Wills</td>
                </tr>
                <tr>
                  <td class="td-first">Funeral Plans</td>
                  <td>e.g. to arrange and pay for a funeral in advance</td>
                </tr>
                <tr>
                  <td class="td-first">Mail Order</td>
                  <td>e.g. catalogues, online retailers</td>
                </tr>
                <tr>
                  <td class="td-first">Market Research</td>
                  <td>e.g. to gather information about consumers’ opinions and preferences</td>
                </tr>
                <tr>
                  <td class="td-first">Media</td>
                  <td>e.g. online, television, radio, newspapers, magazines</td>
                </tr>
                <tr>
                  <td class="td-first">Retail</td>
                  <td>e.g. fashion, groceries, electrical goods, comparison sites, discounts, FMCG</td>
                </tr>
                <tr>
                  <td class="td-first">Telecoms</td>
                  <td>e.g. landline, mobile phones, broadband, digital TV</td>
                </tr>
                <tr>
                  <td class="td-first">Travel</td>
                  <td>e.g. long haul holidays, city breaks, flights, UK breaks, accommodation</td>
                </tr>
                <tr>
                  <td class="td-first">Utilities</td>
                  <td>e.g. gas & electricity switching, other household utilities such as water</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="section-container">
          <b><span class="bold-para">Selected third parties.
            </span></b>
          <p class="para">We may also share your information with selected third parties including:</p>
          <ul>
            <li>Fraud prevention agencies, to verify your identity, prevent fraud and money-laundering and trace those
              responsible (please refer to “Fraud Prevention Agencies” below for further detail)</li>
            <li>Experian, to obtain your credit report and credit score on your behalf and to provide our services to
              you (please
              refer to “Credit Reference Agencies” below for further detail)</li>
            <li>Product providers, such as our business partners who offer you loans, credit cards, insurance,
              mortgages, car
              finance, pensions, investments and other similar products, in order to:</li>
            <li>Pre-fill an application form with the product provider</li>
            <li>Assess your probability of being accepted for a product</li>
            <li>Assess if you are an existing customer and for fraud prevention purposes</li>
            <li>Analysis in order to offer you with better products and services in the future, and for segmentation
              purposes<br>

              Product providers are not given permission to use your data for any other purpose, including marketing
            </li>
            <li>Service providers we use, such as aggregators and comparison sites, who will analyse and profile your
              credit
              information and any other data provided on your behalf, or by you, to find credit products that are likely
              to be
              suited to your financial circumstances and inform you of then through targeted advertising. When you
              search for
              credit offers on our platforms, these service providers will record a ‘soft credit search’ on your credit
              file. This
              record will not be visible to other lenders, so by itself it will not influence their decision to lend to
              you and thus
              will not affect your credit score</li>
            <li>Advertisers that require data, to perform services on our behalf, including selecting and serving
              relevant adverts</li>
            <li>Search engine providers and analytics that enable us to optimize and improve our service</li>
            <li>If we sell or buy any business or assets, in which case we may disclose your personal data to the
              prospective
              seller or buyer of such business or assets (or the buyer or seller’s advisers)</li>
            <li>If we are under a duty to disclose or share your personal data in order to comply with any legal
              obligation, or in
              order to enforce or apply our Terms and other agreements; or to protect the rights, property, or safety of
              Lifezy,
              our customers, or others. This includes exchanging information with other companies and organisations for
              the
              purposes of fraud protection and credit risk reduction</li>
            <li>If we or part or all of our assets are acquired by a third party, one of the transferred assets will
              be the personal
              data held by us about our customers</li>
          </ul>
        </div>
        <div class="section-container">
          <b><span class="bold-para">Credit Reference Agencies.</span></b>
          <p class="para">If you register for our services we will obtain on your behalf, in accordance with our Terms
            and
            Conditions, copies of your credit report and credit score from our partner Credit Reference Agency,
            Experian, with whom we will share your information. Fraud Prevention Agencies and used by those
            organisations for the purposes of checking identity, preventing fraud, tracing and collection of debt.
            Experian may also use your data for statistical analysis. If you apply for a product, the lender will
            undertake a full credit check with one of the Credit Reference Agencies and provide you with further privacy
            information for that product.
          </p></div>
                  <div class="section-container">
          <b><span class="bold-para">Experian Ltd ("Experian")</span></b>
          <p class="para">We operate Score Genius Technology Ltd. in conjunction with UK credit reference agency Experian. By submitting your details to us, you are consenting to them being passed to Experian and to us letting you know every time your Experian Credit Score or report data has been updated. Experian may share the information they collect from us with fraud prevention agencies, including Cifas, who will use it to prevent fraud and money-laundering and to verify your identity. Law enforcement agencies may access and use this information. If fraud is detected, you could be refused certain services, finance, or employment. Further details of how your information will be used by Experian and these fraud prevention agencies can be obtained by writing to Experian at Experian Ltd, PO Box 8000, Nottingham, NG80 7WF. Experian and other organisations may access and use the information recorded by fraud prevention agencies from other countries. More information about your rights in relation to the personal data Experian hold can be found in the 'Your rights to how we use your personal information' section of their Privacy Policy. For further information about how your details will be used by Experian, please see the rest of our Privacy Policy and Experian's Privacy Policy :
            <a href="https://termsandconditions.hdd2.co.uk/scoreandreport" ><span class="blue-txt">https://termsandconditions.hdd2.co.uk/scoreandreport</span></a>
         </p>
        </div>
        <div class="section-container">
          <b><span class="bold-para">Fraud Prevention Agencies.</span></b>
          <p class="para">We will share your personal information with fraud prevention agencies. If inaccurate or false
            information
            is provided and fraud is identified, details of this fraud will be passed to these agencies and to law
            enforcement agencies, who may also access and use this information. We and other organisations may access
            and use from other countries the information recorded by fraud prevention agencies. (difficult to reword)
          </p>
        </div>
        <div class="section-container">
          <h3 class="h-title">4. Energy helpline</h3>
          <b><span class="bold-para">For the online switches through the website: </span></b>
          <p class="para">Our domestic online energy comparison and switching service is supported by our trusted
            partner,
            Fundraising Innovations Ltd (company number 04426857), trading as energy helpline (registered address - The
            Cart Wagon Lodge, Friday Street Farm, Friday Street, East Sutton, Kent, ME17 3DD) who processes data
            securely and in line with Data Protection regulations. As Data Controller the Partner is responsible for
            capturing the information you enter into a switch application in order to forward it to your chosen
            supplier, to enable them to set up your new energy account(s). Once your data is passed to your chosen
            supplier they will process it in line with their Privacy Policy, which we are not responsible for. For
            further information on how your data is processed please refer to the Partner’s Privacy Policy on their
            website. If you are using our offline inbound switching service, you also need to include the following</p>
        </div>
        <div class="section-container">
          <b><span class="bold-para">For the offline switches through our call centre:</span></b>
          <p class="para">Our domestic telephone-based energy comparison and switching service is provided by our
            trusted Partner
            Fundraising Innovations Ltd (company number 04426857), trading as energy helpline (registered address - The
            Cart Wagon Lodge, Friday StreetFarm, Friday Street, East Sutton, Kent, ME17 3DD) who processes data securely
            and in line with Data Protection regulations. As Data Controller the Partner is responsible for capturing the
            information you enter into a switch application and forwarding it to your chosen supplier in order for them
            to set up your new energy account(s). Once your data is passed to your chosen supplier they will process it
            in line with their Privacy Policy, which we are not responsible for. For further information on how your
            data is processed please refer to the Partner’s Privacy Policy on their website</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">5. What’s the legal basis for us using this information?</h3>
          <p class="para">We have an appropriate legal bases on which to collect, use and share data about you. If you
            have any
            questions about the lawful bases upon which we collect and use your personal data, you can contact our Data
            Protection Officer. support@lifezy.co.uk . Our lawful bases may include consent (where you have given consent),
            contract (where processing is necessary for the performance of a contract with you (such as delivering our
            core services to you) and our own legitimate interests. </p>
        </div>
        <div class="section-container">
          <p class="para"><span class="bold-para">Performance of a contract.</span> The information you provide
            us with, and the information we
            collect about you
            from Experian or other third parties enables us to provide you with our services as agreed in our contract
            with you. Without collecting the information you give us we cannot provide our services to you. We need
            certain information from you in order to obtain your credit report and score and to show suitable financial
            offers to you and to provide our other services to you.</p>
        </div>
        <div class="section-container">
          <p class="para">For other types of information, for example, the information we collect based on your use of
            the website
            and information we receive from our sources about you, we process this information for the purposes of
            providing our services to you as agreed in our contract and to enable us to:</p>
          <ul>
            <li>Analyse your behaviour and preferences</li>
            <li>Collect information from devices to be able to improve our services for our customers and improve the
              user
              experience on our website</li>
            <li>Provide a secure website to our customers</li>
          </ul>
          <p class="para"><span class="bold-para">Legitimate interests.</span> We may process your personal data
            for the purposes of our
            legitimate interests or for
            the legitimate interests of our product providers or other suppliers provided that such processing does not
            outweigh your rights and freedoms. For example, we may process your personal information to:</p>
          <ul>
            <li>Provide you with our service and improve upon it</li>
            <li>Comply with laws that apply to us</li>
            <li>Offer you tailored products</li>
            <li>Protect you and us from fraud or other threats</li>
            <li>Conduct analysis, segmentation and profiling of your data in order to provide you with direct
              marketing
              communications and quality control </li>
            <li>Manage our customer relationships</li>
          </ul>
        </div>
        <div class="section-container">
          <p class="para">
            Where we rely on legitimate interests, you have the right to object at any time.
          </p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para"> Consent.</span> Where we are legally required to do so we will always seek your
            consent to process certain types
            of information. You have the right to withdraw or decline your consent at any time.
          </p>
        </div>
        <div class="section-container">
          <h3 class="h-title">6. How long we keep your information for</h3>
          <p class="para">While your account remains active, we keep your information for no longer than is necessary
            depending on
            the purpose for which we are using it. How long we keep your information will depend on the purpose for which
            we use it. While you are a customer of ours, we will only retain your information for as long as is
            necessary for those purposes. After termination of your account, we may continue to use anonymised data
            (which does not identify individual users) which is aggregated with anonymised data of other users. We use
            this aggregated anonymised data for data analysis, profiling and research purposes, for example to gain
            insights about our users. We may also keep your email address to ensure that you no longer receive any
            communications from us as well as your name, date of birth and latest address for fraud prevention purposes
            and for the exercise or defence of a legal claim.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">7. How we protect your information</h3>
          <p class="para">We take date security very seriously and use strict procedures and security to protect your
            information and
            prevent unauthorized access, loss or damage. All information you provide to us, or is provided to us about
            you, is stored on our secure servers.</p>
        </div>
        <div class="section-container">
          <p class="para">We cannot currently guarantee the security of your data transmitted to our website; any
            transmission is at
            your own risk. Where possible, we try to only process your information within the UK and European Economic
            Area (EEA). If we or our service providers transfer personal data outside of the UK or EEA, we always
            require that appropriate safeguards are in place to protect the information when it is processed. Part of
            our customer services function is based in India. We have put in place safeguards to protect personal data
            processed in or accessed from India.</p>
        </div>

        <div class="section-container">
          <h3 class="h-title">8. What direct marketing you’ll receive </h3>
          <p class="para">We offer you the possibility to receive direct marketing information from us. Whenever you
            want, you can
            easily opt out of receiving marketing from us. We’ll normally send direct marketing to you by email, and
            with push notifications to your devices and we may contact you via other methods, for example SMS. You may
            receive the following types of communications from us:</p>
        </div>
        <div class="section-container">
          <ul>
            <li><b><span class="bold-para">Core communications-</span></b> we will send you alerts whenever there is a
              change to your
              credit report, significant
              changes which may impact our service and security alerts pertaining to your Lifezy account and other such
              related
              content. As these communications are such an intrinsic part of using Lifezy they cannot be opted-out of.
            </li>
            <li>
              <b><span class="bold-para">Product recommendations-</span></b> if you explicitly consent to receiving
              them, we’ll make
              personalised product
              recommendations which could help you improve your financial situation. You can unsubscribe whenever you
              like.
            </li>
            <li><b><span class="bold-para">Content communications-</span></b> we’ll send you content such as but not
              limited to tips,
              news, features and on how to
              make the best of your money and other similar content. You can unsubscribe from these at any time. At Lifezy
              we
              are anti-spam and will never spam you.</li>
          </ul>
        </div>
        <div class="section-container">
          <h3 class="h-title">9. How You Can Change Your Preferences</h3>
          <p class="para">If you would like us to stop receiving direct marketing from us, we offer easy ways foryou to
            opt out.
            Whenever you receive direct marketing you will be given an option tounsubscribe.</p>

        </div>
        <div class="section-container">
          <p class="para">You can also write to us and tell us that you do not wish to receive any more marketing
            communications,
            with your full name, address and any other contact details you can provide, to:
          </p>
        </div>
        <div class="section-container">

          <p class="bold-para"><b>
              Data Protection Officer<br>
              Score Genius Technology Limited<br>
              419 Richmond Rd,<br>
              Twickenham<br>
              TW1 2EX
            </b></p>
        </div>
        <div class="section-container">
          <h3 class="h-title">10. Links to and from third party websites</h3>
          <p class="para">Please be aware that if you follow a link to or from our website to a third party website,
            including links
            from websites of our partner networks, advertisers and affiliates these websites have their own privacy
            policies. If you follow a link to or froma third party website, and that we do not accept any responsibility
            or liability for these privacy policies.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">11. Automated decision making</h3>
          <p class="para">We use an automated decision making system to make automated decisions based on personal
            information we
            have about you. This helps us to make sure our decisions are quick and fair, based on what we know.
          </p>
        </div>
        <div class="section-container">
          <p class="para"><span class="bold-para">Identity verification</span><br>
            In order to provide you our services, we must be able to verify your identity. We use an automated decision
            making system to verify the details you provide against those held by third party providers. If you do not
            pass the check using the automated system, we cannot provide our services to you.
          </p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para">Relevant communications</span><br>
            We will use your information to determine the likelihood that your application will be accepted for a
            product, or to ascertain the best manner to present products to you.

          </p>
        </div>
        <div class="section-container">
          <p class="para">

            <b><span class="bold-para">Specific products and services</span></b> <br>
            We will use your information to determine the likelihood that your application will be accepted for a
            product, or to ascertain the best manner to present products to you.

          </p>
        </div>
        <div class="section-container">
          <p class="para">You have the right not to be subject to a decision based solely on automated processing,
            including
            profiling. If you prefer to have a non-automated decision, please inform us. Or if you have any questions
            about automated decision making, please contact us at support@lifezy.co.uk </p>
        </div>
        <div class="section-container">
          <h3 class="h-title">12. Your rights</h3>
          <p class="para">You can exercise specific rights with regards to the data that we hold about you.
            We will provide you with the necessary tools and contact details to be able to exercise your statutory
            rights regarding the information that we hold about you. You will be able to:
          </p>
        </div>
        <div class="section-container">
          <p class="para"><span class="bold-para">Update or correct your information:</span><br>
            through the settings in your account, you will be able to update, or amend any information that we hold
            about you that is incomplete or inaccurate
          </p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para">Request access to your information</span><br>
            you can ask for access to the personal data that we hold about you so that you can check that we are using
            your information in accordance with data protection law.

          </p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para"> Delete your information</span><br>
            you can ask us to delete your personal information, which we will do unless it is necessary for us retain it
            to comply with our legal obligations. Once deleted, it is unlikely we will be able to provide our services.
            We may keep your email address to make sure the restriction is respected in future.

          </p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para"> Object to the use of your information</span><br>
            you may ask that we no longer use yourpersonal information where that use is based on a legitimate interest.

          </p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para"> Restrict the use of your data</span><br>
            You have the right to ‘block’ or suppress further use of your information in certain circumstances (for
            example, where you think the information we are using about you is inaccurate, whilst we verify its
            accuracy). When usage is restricted, we can still store your information, but may not use it further.

          </p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para">Download your data</span><br>
            you can obtain a copy of the data you provided us in a machine-readable format.

          </p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para"> Send it to another controller</span><br>
            where certain conditions apply, you have the rightto have such information transferred directly to a third
            party.

          </p>
        </div>
        <div class="section-container">
          <p class="para">
            <span class="bold-para"> Right to withdraw consent:</span><br>
            You can exercise your rights at any time, and withdraw your consent for us to use your personal data by
            contacting our Data Protection Officer support@lifezy.co.uk .
          </p>
        </div>
        <div class="section-container">
          <h3 class="h-title">13. Changes We Make to our Privacy Policy</h3>
          <p class="para">This page will be updated with any changes we may make to our Privacy Policy and where
            appropriate and
            necessary, you will be notified about changes by email. Please check this page frequently for any updates or
            changes to our Privacy Notice.</p>
        </div>
        <div class="section-container">
          <h3 class="h-title">14. Who are the Information Commissioners Office (ICO)</h3>
          <p class="para">The ICO is the UK’s independent body set up to uphold information rights. </p>
        </div>
        <div class="section-container">
          <h3 class="h-title">15. Your Right to Lodge a Complaint with the ICO</h3>
          <p class="para">If any complaint you raise with us is not dealt with in a satisfactory manner, or you believe
            we are not
            processing your personal data in compliance with data protection law, we ask that you contact our Data
            Protection Officer. However, you may choose to make a complaint to the Information Commissioner’s Office
            (ICO) at any time. Please see the ICO website for further information
            - <a href="https://ico.org.uk/for-the-public/raising-concerns" > <span class="blue-txt">https://ico.org.uk/for-the-public/raising-concerns</span></a> </p>
        </div>
        <div class="section-container">
          <h3 class="h-title">16. Contact Us</h3>
          <p class="para">Please send any questions, complaints, comments or requests about this Privacy Policy to the
            Data Protection Officer at support@lifezy.co.uk .</p>
        </div>
      </div>

    </div>
  </div>
</div>
