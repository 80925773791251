<div class="fixed-top" id="navbar">
    <nav class="navbar navbar-expand-lg sticky-top ">
    <a href="../home" class="navbar-brand">
    <img id="logo_Dark" class="img-fluid lazyload img-logo-size" src="{{imgSrc}}Logo_Dark.png" alt="image not found">
    </a>
    <button type="button" class="btn-demo dispaly-res" data-toggle="modal" data-target="#myModal">
      <img  class="img-fluid" src="{{imgSrc}}menu.svg" alt="image not found">
    </button>
    
    <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
    <ul class="navbar-nav">
        <li class='dropdown'>
            <a data-toggle="dropdown" href="../home" routerLinkActive="active" class="nav-link dropdown-toggle active">Services <i class="fa fa-chevron-down" aria-hidden="true"></i></a>
            <ul class="dropdown-menu" role="menu">
              <a href="../credit-search" routerLinkActive="active" > <li>Credit Search</li></a>
              <a href="../open-banking" ><li>Open Banking</li></a>
              <!---<a href="../marketplace" > <li>Marketplace Services</li></a>
              <a href="../probate" ><li>Probate Services</li></a>-->
              <!---<a href="../webawsservices" ><li>Web Applications/ Hosting</li></a>--->
            </ul>
          </li>
    <li class="nav-item">
    <div> <a class="signuplink" href="/contact-us" routerLinkActive="active" ><button class=" btn btn-sign-up" id="signbtn" >Contact Us
    </button></a></div></li>
    </ul>
    
    </div>
    </nav>
    </div>
    
        <!-- Modal -->
        <div class="modal left fade" id="myModal" tabindex="-1" data-backdrop="static" role="dialog" data-data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
    
                    <div class="modal-header">
              <a href="../home" class="navbar-brand">
                <img id="logo_Dark" class="img-fluid lazyload img-logo-size" src="{{imgSrc}}Logo_Dark.png" alt="image not found">
                </a>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
    
                    <div class="modal-body">
              <a href="../home"  routerLinkActive="active" class="active-res">Services <i class="fa fa-chevron-down" aria-hidden="true"></i></a>
              <a class="credit-respo" href="../credit-search" routerLinkActive="active" >Credit Search</a>
              <a class="credit-respo" href="../open-banking" >Open Banking</a>
              <!---<a class="credit-respo" href="../marketplace" >Marketplace Services</a>
              <a class="credit-respo" href="../probate" >Probate Services</a>-->
              <!---<a class="credit-respo" href="../webawsservices" >Web Applications/ Hosting</a>--->
              <div class="margin-btn"> <a class="signuplink" href="/contact-us" ><button class=" btn btn-sign-up" id="signbtn" >Contact Us
              </button></a></div>
                    </div>
    
                </div><!-- modal-content -->
            </div><!-- modal-dialog -->
        </div><!-- modal -->