// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import * as config from '../assets/app-config.json';
export const environment = {
  production: false,
  name: 'Local',
  configUrl:config.default,
  imgBasURL:"../../assets/creditsu-assets/",
  apiUrl: "https://newtest.dev.creditsu.co.uk/login",
  signUrl: "https://newtest.dev.creditsu.co.uk/login",
  GoogleStore:"https://play.google.com/store/apps/details?id=com.apps.scoregenius",
  PlayStore:"https://www.apple.com/in/ios/app-store/",
  baseUrl: "https://api.dev.creditgenius.co.uk",
  Url: "https://app.dev.creditsu.co.uk",
  SocialMediaSharing: "https://dev.creditsu.co.uk/assets/creditsu-assets/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
