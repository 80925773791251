<router-outlet></router-outlet>
<!-- Cooki popup -->
<div *ngIf="isShown" class="cookieConsentContainer" id="cookieConsentContainer" style="opacity: 1; display: block;">
    <div class="cookieTitle-container">
        <p class="cookieTitle">Cookie Consent</p>
    </div>
    <div class="cookieDesc">
        <p class="cookieDesc-p">
            We use cookies to make your experience with us better
        </p>
    </div>
    <div class="d-flex btn-container">
       <div>
        <button (click)="toggleShow()" type="checkbox"  class="btn-understand">
            I understand
        </button>
       </div>
        <div >
            <button (click)="redirect()" class="btn-findoutmore" type="checkbox">
               Find out more
            </button>          
        </div>
    </div>
</div>