import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
/*var gtag;*/
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'ScoreGeniusPublicSite';
  analytics_title = 'analytics';
  isShown: boolean = false; // open by default


  toggleShow() {
    this.isShown = false;
  }
  redirect() {
    this.router.navigate(['./cookie-policy']);
  }
  constructor(
    private router: Router
  ) {


    const navEndEvent = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    );
    navEndEvent.subscribe((event: NavigationEnd) => {
   /*   gtag("config", "UA-136917539-1", {
        'page_path': event.urlAfterRedirects
      });*/
    })

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });
  }
}
