import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EducateRoutingModule } from './educate-routing.module';
import { ResourceComponent } from './resource/resource.component';
import { ResourceArticleInnerComponent } from './resource-article-inner/resource-article-inner.component';
import { ReactiveFormsModule } from '@angular/forms';
import {SharedModulesModule} from './../shared-modules/shared-modules.module';
import { MyArticleFilterPipe } from '../CustomPipe/article-filter';

@NgModule({
  imports: [
    CommonModule,
    EducateRoutingModule,
    ReactiveFormsModule,
    SharedModulesModule
  ],
  declarations: [
    ResourceComponent,
    ResourceArticleInnerComponent,
    MyArticleFilterPipe
  ]
})
export class EducateModule { }
